#ABOUT3DELING {
    .about-btn {
        text-decoration: none;
        color: white;
        background: transparent;
        width: 220px;
        padding: 13px;
        background-color: $RedColor;
        color: white;
        text-transform: uppercase;
        margin-top: 20px;
        transition: 0.2s ease;
        &:hover {
            background-color: white;
            color: $RedColor;
            border: none;
            transform: scale(1.01);
        }
        @media (max-width: 768px) {
            width: 147px;
            height: 42px;
        }
    }
    #Information {
        margin-top: 10px;
        padding-top: 20px;
        h6 {
            font-family: $FirstFontfamily;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
        }

        .Info {
            display: flex;
            .InfoHead {
                margin-left: 25px;
            }
            i {
                font-size: 20px;
                color: $RedColor;
            }
            h5 {
                font-size: 18px;
                letter-spacing: 1px;
                font-weight: 400;
                cursor: pointer;
                transition: 0.2s all;
                font-family: $FirstFontfamily;
                &:hover {
                    color: $RedColor;
                }
            }
            p {
                font-size: $textFont;
                font-family: $TextFontFamily;
                font-size: 15px;
                text-align: initial;
                line-height: 31px;
                padding: 14px 0px;
            }
            a {
                text-decoration: none;
                color: $RedColor;
                font-weight: 600;
            }
        }
    }
}
