#ABOUT3DELING {
    // height: 500px;
    background-color: $LightBlue;
    padding: 20px 0px;
    .HeadAbout3Deling {
        h2 {
            letter-spacing: 0.5px;
            font-family: $FirstFontfamily;
            position: relative;
            padding-top: 30px;
            font-size: $h1Font;
            text-transform: uppercase;
            font-weight: 500;
            &::before {
                @include BeforeBgAbout3Deling;
                @media (max-width: 1200px) {
                    top: 41px;
                    content: "";
                    position: absolute;
                    height: 19px;
                    width: 240px;
                    left: 80px;
                }
                @media (max-width: 992px) {
                    top: 41px;
                    content: "";
                    position: absolute;
                    height: 19px;
                    width: 200px;
                    left: -40px;
                }

                @media (max-width: 768px) {
                    display: none;
                }
            }
            &::after {
                @include AfterBgAbout3Deling;
                @media (max-width: 1200px) {
                    top: 41px;
                    content: "";
                    position: absolute;
                    height: 19px;
                    width: 240px;
                    right: 80px;
                }
                @media (max-width: 992px) {
                    top: 41px;
                    content: "";
                    position: absolute;
                    height: 19px;
                    width: 200px;
                    right: -40px;
                }
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
    .textAbout3Deling {
        p {
            font-size: $textFont;
            padding-top: 2rem;
        }
    }
    .TextItem {
        padding: 20px 10px;
        p {
            font-size: $textFont;
            font-family: $TextFontFamily;
            letter-spacing: 0.3px;
        }
    }
    button {
        border: 1px solid transparent;
        background-color: $RedColor;
        color: $WhiteColor;
        width: 150px;
        height: 40px;
        transition: 0.2s all;
        font-weight: 500;
        &:hover {
            background-color: $WhiteColor;
            color: $RedColor;
        }
    }
}
