@mixin Flex($justify, $alignItems, $flexDirection) {
    display: flex;
    justify-content: $justify;
    align-items: $alignItems;
    flex-direction: $flexDirection;
}
@mixin BeforeBg {
    top: 10px;
    content: "";
    position: absolute;
    height: 19px;
    width: 200px;

    left: 87px;
    background-color: $grayColor;
}
@mixin AfterBg {
    top: 10px;
    content: "";
    position: absolute;
    height: 19px;
    width: 200px;

    right: 87px;
    background-color: $grayColor;
}
@mixin BeforeBgAbout3Deling {
    top: 41px;
    content: "";
    position: absolute;
    height: 19px;
    width: 240px;
    left: 200px;
    background-color: $grayColor;
}
@mixin AfterBgAbout3Deling {
    top: 41px;
    content: "";
    position: absolute;
    height: 19px;
    width: 240px;
    right: 200px;
    background-color: $grayColor;
}

@mixin BgImg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@mixin HoverProduct {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #20356996;
    width: 100%;
    height: 100%;
}

@mixin SlickProductRight($left: 10px, $right: 10px) {
    top: 50%;

    right: $right;
    position: absolute;
    z-index: 5555;
    width: 40px;
    border: 1px solid transparent;
    padding: 10px 0px;
    background-color: rgba(212, 211, 211, 0.808);
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
}
@mixin SlickProductLeft {
    z-index: 666;
    top: 50%;
    left: 10px;
    position: absolute;
    width: 40px;
    border: 1px solid transparent;
    padding: 10px 0px;
    background-color: rgba(212, 211, 211, 0.808);
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
}
