#OurProduct {
    h1 {
        text-transform: uppercase;
        font-size: $h1Font;
        font-weight: 500;
        padding: 10px 0px;
        font-family: $FirstFontfamily;
        letter-spacing: 0.5px;
    }
    .ProductItem {
        @include Flex(none, center, column);
        h5 {
            font-family: $FirstFontfamily;
            font-size: 17px;
        }
        .ProductLogo {
            img {
                width: 90px !important;
                object-fit: cover;
                @media (max-width: 768px) {
                    width: 50px;
                }
            }
        }
    }

    .ProductItemText {
        text-align: center;
        margin: 15px 0px !important;
        h5 {
            display: inline-block;
        }
        p {
            font-size: $textFont;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0px;
            line-height: 22px;
            font-family: $TextFontFamily;
        }
    }
    .col-lg-3 {
        margin: 15px 0px;
    }
}
