#FooterPromotion {
    margin-top: 20px;

    .PromotionLogos {
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
        }
        .Logo {
            img {
                width: 150px;
                cursor: pointer;
                filter: grayscale(1);
                transition: 0.2s all;
                opacity: 0.7;
                transform: scale(1);
                &:hover {
                    filter: grayscale(0);
                    opacity: 1;
                    transform: scale(1.05);
                }
                @media (max-width: 768px) {
                    width: 135px;
                }
            }
            @media (max-width: 768px) {
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
}
