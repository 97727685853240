#WORKFLOW {
    padding: 30px 0px;
    h1 {
        font-family: $FirstFontfamily;
        text-transform: uppercase;
        font-size: $h1Font;
        font-weight: 500;
        position: relative;
        margin-top: 50px;
        display: inline-block;
      
        &::before {
            top: 17px;
            content: "";
            position: absolute;
            height: 19px;
            width: 203px;
            left: 505px;
            background-color: #c4c4c4;
            @media (max-width: 1200px) {
                top: 17px;
                content: "";
                position: absolute;
                height: 19px;
                width: 200px;
                right: -306px;
            }
            @media (max-width: 992px) {
                top: 17px;
                content: "";
                position: absolute;
                height: 12px;
                width: 124px;
                left: -155px;
             
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
        &::after {
            top: 17px;
            content: "";
            position: absolute;
            height: 19px;
            width: 203px;
            right: 505px;
            background-color: #c4c4c4;
            @media (max-width: 1200px) {
                top: 17px;
                content: "";
                position: absolute;
                height: 19px;
                width: 200px;
                left: -306px;
            }
            @media (max-width: 992px) {
                top: 17px;
                content: "";
                position: absolute;
                height: 12px;
                width: 124px;
                left: 505px
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    .TextWorkFlow {
        margin-top: 40px;
        p {
            font-size: $textFont;
            font-family: $TextFontFamily;
            letter-spacing: 0.3px;
            line-height: 27px;
            @media (max-width:768px) {
                padding: 9px 21px;
                
            }
        }
    }

    #utvs_62305b8ce581b {
        overflow: hidden;
        height: 600px;
        width: 1240px !important;
        position: relative;
        max-width: 100%;
        border: 1px solid;
      
        margin: 0 auto;
        padding: 10px 7px;
        box-sizing: border-box;
        @media (max-width:768px) {
            height: 200px;

            
        }

        .fa-youtube-play {
            position: absolute;

            color: white;
            bottom: 10px;
            left: 20px;
            cursor: pointer;
            @media (max-width:768px) {
                font-size: 25px;
                
            }
        }
        .ut-video-loading {
            .fa-spinner {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
            }
        }
        img{
            max-width: 100%;
        }
    }
}
