#Blogs {
    a {
        text-decoration: none;
        color: black;
    }
    .BlogLeft {
        .DateFormats {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #efefef;
            display: flex;
            flex-direction: column;
            #date {
                font-size: 50px;
                text-align: end;
            }
            #year {
                font-size: 14px;
                font-weight: 400;
                text-align: end;
            }
        }
        ul {
            padding: 0;
            text-align: end;
            @media (max-width: 768px) {
                display: flex;

                justify-content: space-evenly;
                align-items: center;
            }
            li {
                display: flex;

                align-items: center;

                a {
                    text-decoration: none;
                    color: black;
                }
                span {
                    margin-left: 10px;
                    margin-bottom: 0px;
                    font-size: 10px;
                    text-decoration: none;
                    color: black;
                    transition: 0.3s ease;
                    cursor: pointer;
                    font-weight: 500;
                    &:hover {
                        color: $mainTextColor;
                    }
                    @media (max-width: 768px) {
                        margin: 0;
                    }
                }
                &:last-child {
                    font-weight: 600;
                }
            }
        }
    }
    .CenterBlog {
        margin-bottom: 100px;
        p {
            font-size: 14px;
        }
        h1 {
            font-family: $FirstFontfamily;
            font-size: 25px;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
                color: $mainTextColor;
            }
            @media (max-width: 768px) {
                text-align: center;
                font-variant: unicase;
            }
        }
        h4 {
            font-size: 20px;
            font-weight: 500;
        }
        img {
            border-radius: 7px;
            width: 100%;
            margin-bottom: 20px;
            height: 480px;
            object-fit: cover;
        }
        iframe {
            width: 100%;
            height: 480px;
            border-radius: 7px;
        }
    }
    .RightBlog {
        margin-top: 39px;
        input {
            width: 340px;
            padding: 8px;
            &:focus {
                box-shadow: none;
            }
            @media (max-width: 768px) {
                width: 300px;
                padding: 5px;
            }
        }
        p {
            margin-top: 15px;
            font-size: 15px;
            font-family: $FirstFontfamily;
        }

        #recentPost {
            padding-left: 10px;
            padding-right: 25px;
            margin-bottom: 40px;
            li {
                margin: 4px 0px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;

                a {
                    i {
                        color: #151515;
                        margin-right: 10px;
                    }
                }
            }
        }
        #Archive {
            @extend #recentPost;
        }
        #category {
            @extend #recentPost;
        }
        #ButtonList {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            li {
                margin: 3px 3px;
                button {
                    padding: 5px 5px;
                    border: 1px solid $mainTextColor;
                    color: $mainTextColor;
                    transition: 0.3s ease;
                    &:hover {
                        color: white;
                        background-color: $mainTextColor;
                    }
                }
            }
        }
    }
}
