nav {
    .BackGrondtext {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        overflow: hidden;
        height: 660px;
        @media (max-width: 768px) {
            padding: 0;
            height: 651px;
        }

        background-color: rgba(3, 3, 3, 0.592);
        top: 0px;
        @media (max-width: 768px) {
            height: auto;
        }
        .CssSlider {
            .SlidersItemsNav {
                .SliderItemtext {
                    border-bottom: 1px solid white;
                    display: inherit;
                    width: 720px;

                    .tricker {
                        height: 85px;
                        overflow: hidden;
                        display: none;
                        @media (max-width: 768px) {
                            height: 80px;
                        }
                    }
                    @media (max-width: 600px) {
                        width: 400px;
                    }
                    .tricker {
                        h1 {
                            color: white;
                            margin: 0;
                            height: 50px;
                            line-height: 78px;
                            font-size: 60px;
                            text-align: center;
                            text-transform: uppercase;

                            @media (max-width: 768px) {
                                font-size: 30px;
                            }
                            span {
                                color: $DarkBlue;
                                font-weight: bold;
                            }
                        }
                    }
                    .subline {
                        width: 100px;
                        height: 2px;
                        margin: 10px auto;
                        background-color: #181818;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            padding: 250px 0px;
        }

        p {
            display: inline-block;
            color: $WhiteColor;
            font-size: 1.2rem;
            border-bottom: 1px solid $WhiteColor;

            @media (max-width: 768px) {
                font-size: 10px;
            }
        }

        .SliderItemtext {
            width: 703px;
            overflow: hidden;
        }

        button {
            a {
                text-decoration: none;
                color: white;
            }
            background: transparent;
            width: 210px;
            height: 51px;
            color: $WhiteColor;
            padding: 8px 2px;
            text-transform: uppercase;
            border: 1px solid $WhiteColor;
            margin-top: 20px;
            transition: 0.2s ease;
            &:hover {
                background-color: $RedColor;
                color: white;
                border: none;
                transform: scale(1.01);
            }
            @media (max-width: 768px) {
                width: 147px;
                height: 42px;
            }
        }
    }
    .nav {
        width: 100%;
        height: 65px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        line-height: 65px;
        text-align: center;
        z-index: 666;
        .logo {
            @media (max-width: 768px) {
                padding-left: 10px;
            }
            float: left;
            width: auto;
            height: auto;
            padding-left: 3rem;
            a {
                text-decoration: none;
                color: #fff;
                font-size: 1rem;

                img {
                    width: 100px;
                }
            }
        }
        .container {
            padding: 0;
            @media (max-width: 992px) {
                display: flex;
            }
            @media (max-width: 768px) {
                display: block;
            }
        }
    }

    .nav div.main_list {
        height: 65px;
        float: right;
    }

    .nav div.main_list ul {
        width: 100%;
        height: 65px;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 65px;
            padding: 0;
            padding-right: 1rem;
            color: $mainTextColor;
            i {
                font-size: 6px;
                margin-left: 9px;
                margin-top: 0px;
                color: white;
            }
            a {
                margin-left: 4px;
                text-decoration: none;
                color: #fff;
                line-height: 65px;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                transition: 0.2s linear;
                font-family: $fontMonserrat;
                &:hover {
                    color: $RedColor;
                }
            }
        }
    }

    .navTrigger {
        display: none;
    }

    .nav {
        background-color: rgba(0, 0, 0, 0.592);

        padding-bottom: 10px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        &:hover {
            background-color: rgba(0, 0, 0, 0.836);
        }
    }

    /* Media qurey section */

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .container {
            margin: 0;
        }
    }

    @media screen and (max-width: 768px) {
        .navTrigger {
            display: block;
        }
        .nav div.logo {
            padding-left: 10px;
        }
        .nav div.main_list {
            width: 100%;
            height: 0;
            overflow: hidden;
            ul {
                flex-direction: column;
                width: 100%;
                height: 100vh;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: white;
                /*same background color of navbar*/
                background-position: center top;
                li {
                    width: 100%;
                    text-align: left;
                    a {
                        text-align: center;
                        width: 100%;
                        font-size: 1rem;
                        padding: 20px;
                        color: black;
                        font-family: $fontMonserrat;
                    }
                }
            }
        }
        .nav div.show_list {
            height: auto !important;
            display: none;
        }
        .nav div.media_button {
            display: block;
        }
    }

    /* Animation */
    /* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

    .navTrigger {
        cursor: pointer;
        width: 30px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
    }

    .navTrigger i {
        background-color: #fff;
        border-radius: 2px;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
    }

    .navTrigger i:nth-child(1) {
        -webkit-animation: outT 0.8s backwards;
        animation: outT 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }

    .navTrigger i:nth-child(2) {
        margin: 5px 0;
        -webkit-animation: outM 0.8s backwards;
        animation: outM 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }

    .navTrigger i:nth-child(3) {
        -webkit-animation: outBtm 0.8s backwards;
        animation: outBtm 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }

    .navTrigger.active i:nth-child(1) {
        -webkit-animation: inT 0.8s forwards;
        animation: inT 0.8s forwards;
    }

    .navTrigger.active i:nth-child(2) {
        -webkit-animation: inM 0.8s forwards;
        animation: inM 0.8s forwards;
    }

    .navTrigger.active i:nth-child(3) {
        -webkit-animation: inBtm 0.8s forwards;
        animation: inBtm 0.8s forwards;
    }

    @-webkit-keyframes inM {
        50% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    @keyframes inM {
        50% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(45deg);
        }
    }

    @-webkit-keyframes outM {
        50% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    @keyframes outM {
        50% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(45deg);
        }
    }

    @-webkit-keyframes inT {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }
        50% {
            -webkit-transform: translateY(9px) rotate(0deg);
        }
        100% {
            -webkit-transform: translateY(9px) rotate(135deg);
        }
    }

    @keyframes inT {
        0% {
            transform: translateY(0px) rotate(0deg);
        }
        50% {
            transform: translateY(9px) rotate(0deg);
        }
        100% {
            transform: translateY(9px) rotate(135deg);
        }
    }

    @-webkit-keyframes outT {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }
        50% {
            -webkit-transform: translateY(9px) rotate(0deg);
        }
        100% {
            -webkit-transform: translateY(9px) rotate(135deg);
        }
    }

    @keyframes outT {
        0% {
            transform: translateY(0px) rotate(0deg);
        }
        50% {
            transform: translateY(9px) rotate(0deg);
        }
        100% {
            transform: translateY(9px) rotate(135deg);
        }
    }

    @-webkit-keyframes inBtm {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }
        50% {
            -webkit-transform: translateY(-9px) rotate(0deg);
        }
        100% {
            -webkit-transform: translateY(-9px) rotate(135deg);
        }
    }

    @keyframes inBtm {
        0% {
            transform: translateY(0px) rotate(0deg);
        }
        50% {
            transform: translateY(-9px) rotate(0deg);
        }
        100% {
            transform: translateY(-9px) rotate(135deg);
        }
    }

    @-webkit-keyframes outBtm {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }
        50% {
            -webkit-transform: translateY(-9px) rotate(0deg);
        }
        100% {
            -webkit-transform: translateY(-9px) rotate(135deg);
        }
    }

    @keyframes outBtm {
        0% {
            transform: translateY(0px) rotate(0deg);
        }
        50% {
            transform: translateY(-9px) rotate(0deg);
        }
        100% {
            transform: translateY(-9px) rotate(135deg);
        }
    }

    .affix {
        padding: 0;
        background-color: #111;
    }

    .myH2 {
        text-align: center;
        font-size: 4rem;
    }
    .myP {
        text-align: justify;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 20px;
    }
    @media all and (max-width: 700px) {
        .myP {
            padding: 2%;
        }
    }

    #About {
        position: relative;
        ul {
            border-top: 2px solid red;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            align-items: baseline;

            z-index: 666666;
            height: auto;
            width: 130px;
            height: auto;
            background-color: #203569e6;
            padding: 0;
            position: absolute;
            top: 62px;
            left: -75px;
            right: 0px;
            display: flex;
            flex-direction: column;
            display: none;
            transition: 0.4s ease;
            @media (max-wdith: 578px) {
                left: 0;
            }

            .Aboutİtem {
                text-align: left;
                padding-left: 20px;
                height: 35px;
                a {
                    font-size: 11px;
                    color: white !important;
                    transition: 0.3s ease;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 500;
                    &:hover {
                        color: $RedColor !important;
                        font-weight: 500;
                    }
                }
                @media (max-width: 768px) {
                    background-color: rgb(0, 0, 0);
                }
            }
        }
        &:hover ul {
            display: flex;
        }
    }
    #Project {
        position: relative;
        ul {
            border-top: 2px solid red;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            align-items: baseline;

            z-index: 666666;
            height: auto;
            width: 210px;
            height: auto;
            background-color: #203569e6;
            padding: 0;
            position: absolute;
            top: 62px;
            left: -27px;
            right: 0px;
            display: flex;
            flex-direction: column;
            display: none;
            transition: 0.4s ease;
            @media (max-wdith: 608px) {
                left: 0;
            }

            .ProjectItem {
                text-align: left;
                padding-left: 20px;
                height: 35px;
                a {
                    font-size: 11px;
                    color: white !important;
                    transition: 0.3s ease;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 500;
                    &:hover {
                        color: $RedColor !important;
                        font-weight: 500;
                    }
                }
                @media (max-width: 768px) {
                    background-color: rgb(0, 0, 0);
                }
            }
        }
        &:hover ul {
            display: flex;
        }
    }

    #Product {
        position: relative;

        ul {
            align-items: baseline;
            border-top: 2px solid red;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            z-index: 666666;
            height: auto;
            width: 220px;
            height: auto;
            background-color: #203569e6;
            padding: 0;
            position: absolute;
            top: 62px;
            left: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            display: none;
            transition: 0.4s ease;
            .ProductItem {
                text-align: left;
                padding-left: 20px;
                height: 35px;
                a {
                    font-size: 11px;
                    color: white !important;
                    transition: 0.3s ease;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 500;
                    &:hover {
                        color: $RedColor !important;
                        font-weight: 500;
                    }
                }
                @media (max-width: 768px) {
                    background-color: rgb(0, 0, 0);
                }
            }
        }
        &:hover ul {
            display: flex;
        }
    }
}

#videoBkg {
    filter: brightness(0.8);
    height: 710px;
    top: 0;
    left: fixed;
    position: absolute;
    right: 0;
    width: 100%;
    object-fit: cover;
    @media (max-width: 768px) {
        height: 716px;
    }
}
.ItemSVideoText {
    padding: 0 !important;
}

.SlickNav {
    background: white !important;
    position: fixed !important;
    z-index: 66666;
    .navTrigger {
        i {
            background-color: $RedColor;
        }
    }

    ul {
        li {
            i {
                color: #203569 !important;
            }
            a {
                color: black !important;
                transition: 0.2s all;
                &:hover {
                    color: $RedColor !important;
                }
            }
        }
    }
}
