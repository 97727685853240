#Isotope {
    padding: 30px 0px;
    h1 {
      font-size: $h1Font;
      padding: 11px 1px;
      font-family: $FirstFontfamily;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  
    .button {
      button {
        display: inline-block;
        padding: 10px 18px;
        margin-bottom: 10px;
        background-color: white;
        border: 1px solid grey;
        color: grey;
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 300;
  
        cursor: pointer;
        text-transform: uppercase;
        @media (max-width: 992px) {
          height: 100px;
  
          width: auto !important;
        }
        @media (max-width: 768px) {
          height: auto;
  
          width: auto !important;
        }
        .button:active {
          background-color: $RedColor;
          border-color: transparent;
          color: white;
        }
      }
    }
  
    button:hover {
      background-color: $RedColor;
      border-color: transparent;
      transition: all 0.25s ease;
      color: white;
    }
  
    $baseline: 12;
    $gutter: 20;
    $border-radius: 0;
    $inner-width: 1200px;
  
    //== COLOURS
    $color-white: #ffffff;
    $color-black: #393838;
    $color-muted: #e3e3e3;
    $color-tomato: #ff5252;
  
    $color-body: $color-black;
    $color-main: $color-black;
    $color-accent: $color-tomato;
    $color-link-hover: $color-black;
  
    $font-size-x-small: 12;
    $font-size-small: 14;
    $font-size-normal: 16;
    $font-size-large: 18;
    $font-size-x-large: 22;
    $font-size-xx-large: 26;
    $font-size-xxx-large: 38;
    $font-size-ultra-large: 40;
    $font-size-ultra-x-large: 58;
  
    $font-size-body: $font-size-normal;
    $font-size-h1: $font-size-xxx-large;
    $font-size-h2: $font-size-xx-large;
    $font-size-h3: $font-size-x-large;
    $font-size-h4: $font-size-large;
    $font-size-h5: $font-size-normal;
  
    //-- FONT WEIGHT
    $font-weight-light: 300;
    $font-weight-regular: 400;
    $font-weight-medium: 500;
    $font-weight-bold: 700;
    $font-weight-heavy: 900;
  
    //== TRANSITIONS
  
    $transition-default: 0.5s ease-in;
    $transition-fast: 0.25s ease-in;
  
    //== MEDIA QUERIES
  
    $breakpoint-mobile: 320px;
    $breakpoint-phablet: 600px;
    $breakpoint-tablet: 768px;
    $breakpoint-laptop: 1024px;
    $breakpoint-desktop: 1280px;
    $breakpoint-desktop-large: 1440px;
    $breakpoint-desktop-x-large: 1600px;
  
    /* ********* SASS EXTENDS  ********* */
  
    %list-reset {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  
    %inner {
      width: $inner-width;
      margin: 0 auto;
    }
  
    /* ********* SASS FUNCTIONS ********* */
  
    $context: $font-size-body;
  
    @function em($pixels, $context: $context) {
      @return ($pixels/$context) * 1em;
    }
  
    /* ********* SASS MIXINS ********* */
  
    @mixin gradient($image: none, $from: $color-white, $to: $color-muted) {
      background: $image $from;
      background: $image, linear-gradient(to bottom, $from 0%, $to 100%);
    }
  
    h2 {
      font-weight: $font-weight-regular;
    }
  
    .copyright {
      font-style: italic;
    }
  
    .content {
      @extend %inner;
      text-align: center;
    }
  
    p {
      margin-bottom: em($baseline/2);
    }
  
    ul {
      margin-bottom: 1em;
      list-style: none;
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: space-around;
    }
  
    /* FILTERS */
  
    .button {
      display: block;
      width: 100%;
      padding: 20px 0px;
    }
  
    button {
      margin: 0.5em;
      padding: 0.5rem;
      border: 1px solid $color-black;
      color: $color-black;
      background: none;
      font-size: 0.7em;
      text-transform: uppercase;
      font-size: em($font-size-small);
      @media (max-width:768px) {
        font-size: 16px;
        
      }
    }
  
    button.is-active {
      background: $RedColor;
      border: transparent;
      color: $color-white;
    }
  
    /* LIST */
  
    .list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  
    .list__item {
      display: block;
      width: 100%;
      height: 18em;
      overflow: hidden;
      margin-bottom: 1em;
      text-align: center;
      font-size: 1em;
      text-transform: uppercase;
      color: white;
      box-shadow: 0 0 2px rgba($color-black, 0.2);
      position: relative;
      img{
        width: 512px;
      object-fit: cover;
      }
  
  
      .IsotopeInfo{
        position: absolute;
        bottom: 0px;
        background-color: #20356996;
        color: white;
        text-align: inherit;
        left: 0;
        right: 0;
        width: 100%;
        font-size: 13px;
        height: 59px;
        padding: 11px 1px;
        transition: .2s ease;
        p{
          font-size: 16px;
          font-weight: 400;
        }
      }
      .HoverItemIsotope {
        width: 100%;
        height: 100%;
  
        background-color: #20356996;
        color: white;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(-320px);
        transition: 0.3s ease;
       a{
         text-decoration: none;
         color: white;
        .HoverBody {
          p {
            font-size: 12px !important;
          }
        }
       }
      }
      &:hover .HoverItemIsotope {
        transform: translateY(0px);
      }
      &:hover .IsotopeInfo{
        transform: translateY(100px);
      }
      @media (max-width:768px) {
        max-width: 100%;
        
      }
    }
  
    .list__item__title {
      display: block;
      margin-top: 30%;
    }
  
    @media screen and (min-width: 600px) {
      .list {
        display: flex;
        flex-wrap: wrap;
      }
      .list__item {
        width: calc(33% - 1em);
        margin-right: 1em;
      }
      .list__item:nth-child(3n) {
        clear: left;
      }
      .list__item:nth-child(-n + 2) {
        margin-top: 0;
      }
    }
    @media screen and (min-width: 768px) {
      button {
        margin: 0.5em;
        padding: 0.5em;
      }
  
      .list__item {
        width: calc(25% - 1em);
        
      }
      .list__item:nth-child(2n) {
        margin-right: 1em;
      }
      .list__item:nth-child(3n) {
        clear: none;
        margin-right: 0;
      }
      .list__item:nth-child(4n) {
        clear: left;
      }
      .list__item:nth-child(-n + 3) {
        margin-top: 0;
      }
    }
  }
  