#FooterPromotion {
    .FooterPromotionItem {
        display: flex;
        justify-content: space-around;

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            img {
                width: 135px;
            }
        }
    }
}
