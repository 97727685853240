#OurTeam {
    padding: 20px 0px;
    h1 {
        text-align: center;
        font-family: $FirstFontfamily;
        font-size: 50px;
        padding: 20px 0px;
        display: inline-block;
        position: relative;
      
    }
    p {
        FONT-SIZE: 15px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 27px;
    
        
        font-family: $TextFontFamily;
        letter-spacing: 0.3px;
        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 30px;
        }
    }
}

#OutTeamItems {
 
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .teamItemBody {
        padding: 0px 10px;
        .teams {
            margin: 15px 0px;
            @media (max-width: 768px) {
                margin: 5px 0px;
            }
            i {
                color: $RedColor;
                font-size: 20px;
            }
            span {
                font-family: $FirstFontfamily;
                margin-left: 10px;
                font-size: 22px;
                letter-spacing: 1px;
            }
            p {
                font-size: 15px;
                font-weight: 600;
                @media (max-width: 768px) {
                    font-size: 13px;
                }
            }
        }
    }
    ul {
        width: 88%;
        display: flex;
    
        justify-content: space-around;
        @media (max-width: 576px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
}
#AboutData {
    padding: 40px 0px;
    .AboutLeft {
        img {
            width: 100%;
            margin: 5px 0px;
        }
    }
    .AboutRight{
        h1{
            display: flex;
            text-align: start;
            flex-direction: column;
            font-size: 40px;
            color: black;
            padding: 10px 0px;
            span{
                color: $DarkBlue;
                font-size: 20px;
            }
        }
        p{
            line-height: 30px;
        }
    }
}
