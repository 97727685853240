#Application {
    margin-top: 4rem;
    padding: 3rem 0rem;

    h1 {
        letter-spacing: 0.5px;
        font-size: 30px;
        position: relative;
        font-weight: 500;
        font-family: $FirstFontfamily;
        &::before {
            @media (max-width: 1200px) {
                top: 10px;
                content: "";
                position: absolute;
                height: 19px;
                width: 200px;
                left: -65px;
                background-color: #c4c4c4;
            }

            @media (max-width: 992px) {
                top: 15px;
                content: "";
                position: absolute;
                height: 8px;
                width: 86px;
                left: -46px;
                background-color: #c4c4c4;
            }
            @include BeforeBg;
            @media (max-width: 768px) {
                display: none;
            }
        }
        &::after {
            @media (max-width: 1200px) {
                top: 10px;
                content: "";
                position: absolute;
                height: 19px;
                width: 200px;
                right: -65px;
                background-color: #c4c4c4;
            }
            @media (max-width: 992px) {
                top: 15px;
                content: "";
                position: absolute;
                height: 8px;
                width: 86px;
                right: -46px;
                background-color: #c4c4c4;
            }
            @include AfterBg;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    .SocialApplication {
        .Icons {
            @include Flex(center, center, none);
            img {
                width: 100px;
                object-fit: cover;
                filter: grayscale(0);
                &:hover {
                    filter: grayscale(1);
                    transform: scale(1.04);
                }
                &:hover .IconItem {
                    overflow: hidden;
                    border-radius: 50%;
                    width: 125px;
                    height: 125px;
                    border: 4px solid;
                    @include Flex(center, center, none);

                    transition: 0.1s all;
                }
            }
            .IconItem {
                &:hover {
                    overflow: hidden;
                    border-radius: 50%;
                    width: 125px;
                    height: 125px;
                    border: 4px solid;
                    @include Flex(center, center, none);

                    transition: 0.1s all;
                }
            }
        }
        h3 {
            font-family: $FirstFontfamily;
            text-align: center;
            padding: 15px 0px;
            font-size: 17px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
        p {
            padding: 0px 30px;
            letter-spacing: 0.5px;
            font-size: 15px;
            line-height: 26px;
            font-family: $TextFontFamily;
            @media (max-width: 768px) {
                padding: 28px;
            }
        }
    }
    .SocialContainer {
        padding: 40px 0px;
        .col-lg-6 {
            margin: 15px 0px;
        }
        .col-lg-12 {
            width: 700px;
            margin: 0 auto;
        }
    }
}
