$grayColor: #c4c4c4;
$DarkBlue: #1794db;
$LightBlue: #d8ecff;
$VeryligthBlue: #edf6ff;
$RedColor: #ee1d24;
$WhiteColor: #ffffff;
$textFont: 1rem;
$FirstFontfamily: "Barlow Condensed", sans-serif;
$TextFontFamily: "Open Sans", sans-serif;
$footerBg: #203569;
$mainTextColor: #203569;
$h1Font: 40px;
$fontMonserrat: "Montserrat", sans-serif;
