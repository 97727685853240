#Product {
    .slider-wrapper {
        position: relative;
        width: 100%;
        height: 600px;
        overflow: hidden;

        @media (max-width: 950px) {
            height: auto;
        }
    }
    .image {
        position: relative;
        height: 600px;

        @media (max-width: 768px) {
            height: auto;
        }
        .InfoSliderItem {
            p {
                text-align: center;
            }
        }

        .infos {
            background-color: rgba(0, 0, 0, 0.664);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 5555;
            right: 0px;
            left: 0px;
            padding: 15px 0px;
            @include Flex(center, center, none);
            @media (max-width: 576px) {
                height: auto;
                padding: 21px 0px;
            }
            img {
                width: 100px;
                margin: 0 auto;
                @media (max-width: 576px) {
                    width: 40px;
                    padding: 8px;
                }
            }
            h2 {
                font-size: 37px;
                font-weight: 600;
                margin-top: 15px;
                text-align: center;
                border-top: 2px solid white;
                border-bottom: 2px solid white;
                padding: 20px 0px;
                color: white;
                text-transform: uppercase;
                span {
                    color: $RedColor;
                }
                @media (max-width: 576px) {
                    font-size: 14px;
                    padding: 11px;
                }
            }
            p {
                font-size: 15px;
                color: white;
                @media (max-width: 576px) {
                    font-size: 13px;
                    margin: 0;
                    padding: 0px 20px;
                }
            }
            button {
                a {
                    color: white;
                    text-decoration: none;
                }
                text-align: center;
                padding: 15px 10px;
                background-color: rgba(128, 128, 128, 0.562);
                width: 100%;
                color: white;
                font-size: 17px;
                border: 1px solid transparents;
                filter: blur(0.2px);
                transition: 0.2s all;
                &:hover {
                    background-color: $mainTextColor;
                    color: white;
                    border: 1px solid transparent;
                }
                @media (max-width: 576px) {
                    padding: 5px 5px;
                    width: auto;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    filter: blur(0px);
                }
            }
        }
    }

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: none;
        touch-action: none;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;
    }

    .slick-list:focus {
        outline: none;
    }

    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide img {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        zoom: 1;
    }

    .slick-track:before,
    .slick-track:after {
        content: "";
        display: table;
    }

    .slick-track:after {
        clear: both;
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
        display: none;
    }

    .slick-slide img {
        display: block;
        max-width: 100%;
    }

    .slick-slide.dragging img {
        pointer-events: none;
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-slide > .slick-slider-img {
        position: relative;
        display: block;
        width: 100%;
        filter: blur(2px);
    }

    .slick-slide img {
        display: block;
        width: 100%;
    }

    .slick-slide img.slick-loading {
        display: none;
    }

    .fa-chevron-left {
        background: rgba(185, 184, 184, 0.774);
        border: none;
        border-radius: none;
        position: absolute;
        top: 40%;
        transform: translateY(-20%);
        left: 10px;
        padding: 1em 1.5em;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 400;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
            color: $mainTextColor;
        }
        @media (max-width: 576px) {
            left: 5px;
            padding: 4px 10px;
        }
    }

    .fa-chevron-right {
        background: rgba(185, 184, 184, 0.774);
        border: none;
        border-radius: none;
        position: absolute;
        cursor: pointer;
        top: 40%;
        transform: translateY(-20%);
        right: 10px;
        padding: 1em 1.5em;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 400;
        transition: 0.2s ease;
        &:hover {
            color: $mainTextColor;
        }
        @media (max-width: 576px) {
            right: 5px;
            padding: 4px 10px;
        }
    }

    /* progress bar */
    .slider-progress {
        width: 100%;
        height: 10px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        bottom: 0;
        @media (max-width: 576px) {
            height: 2px;
        }
    }
    .slider-progress .progress {
        width: 0%;
        height: 10px;
        background: $RedColor;
    }
}

#ProductMeshModel {
    padding: 40px 0px;
    margin: 15px 0px;
    h1 {
        font-family: $FirstFontfamily;
        font-size: 38px;
    }
    .LeftP {
        padding: 30px 0px;
        margin: 10px 0px;
        p {
            line-height: 40px;
        }
    }
    .RightP {
        @extend .LeftP;
    }
    h5 {
        display: inline-block;

        font-family: $FirstFontfamily;
        font-size: 20px;
        position: relative;
        &::after {
            position: absolute;
            content: "";

            top: 28px;
            left: 77px;
            background-color: $RedColor;
            width: 40px;
            height: 2px;
        }
    }

    .IncludeApp {
        padding: 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .AppItem {
            display: flex;
            align-items: center;
        }
        p {
            font-weight: 500;
            margin: 0;
            margin-left: 10px;
            cursor: pointer;
        }
        &:hover {
            color: $RedColor;
        }
    }
}

#FooterSliderSection {
    .card-img-top {
        width: 100%;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }
    .card {
        height: 200px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .HoverItemProduct {
            @include HoverProduct;

            @include Flex(center, center, none);
            opacity: 0;
            transition: 0.3s ease;
            .HoverProductItem {
                @include Flex(none, center, column);
                text-align: center;
                i {
                    font-size: 20px;
                    color: white;
                    margin: 10px 0px;
                }
                p {
                    font-size: 14px;
                    color: white;
                }
            }
        }
        &:hover .HoverItemProduct {
            opacity: 1;
        }
        img {
            transform: scale(1);
            transition: 0.3s ease;
        }
        &:hover img {
            transform: scale(1.2);
        }
    }

    .card-slider {
        position: relative;
        .fa-chevron-right {
            @include Flex(center, center, none);
            @include SlickProductRight;
        }
        .fa-chevron-left {
            @include Flex(center, center, none);
            @include SlickProductLeft;
        }
    }
}

#ProductVideo {
    width: 100%;
    height: 100vh;
    background-color: rgb(167, 163, 163);

    iframe {
        width: 80%;
        height: 100vh;
        padding: 10px 0px;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
}
#ProductImages {
    img {
        width: 100%;
        object-fit: cover;
    }
}
