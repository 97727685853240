// Fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lexend+Tera:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

// Variables
@import "front/variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Sweet Alert2
@import "~sweetalert2/src/sweetalert2";

// AOS
@import '~aos/dist/aos.css';

// Slick Carousel
@import '~slick-carousel-latest/slick/slick';

// Lightgallery
@import '~lightgallery/scss/lightgallery';
@import '~lightgallery/scss/lg-thumbnail';
@import '~lightgallery/scss/lg-zoom';

// Custom styles
@import "front/mixim";
@import "front/navbars";
@import "front/application";
@import "front/about";
@import "front/information";
@import "front/workflow";
@import "front/our-product";
@import "front/promotion";
@import "front/isotope";
@import "front/footer-promotion";
@import "front/footer";
@import "front/blog";
@import "front/product";
@import "front/our-team";
@import "front/contact";
@import "front/isotope-item";

*,
*::before,
*::after {
    box-sizing: border-box;
    offset: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}
ul {
    padding: 0;
    list-style: none;
}
main{
    overflow-x: hidden;
}
#back-to-top {
    background-color: $mainTextColor;
    bottom: 20px;
    color: #fff;
    display: none;
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 40px;
    z-index: 99;
}
#back-to-top a {
    color: inherit;
}
